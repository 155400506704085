import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import Api from '../../common/services/api';
import { getApiHeaders } from '../../common/services/api/utils';

import type {
  Event,
  EventForUserDto,
  EventForAdminDto,
  EventStatus,
  GuestCounts,
} from './types';
import type { SimpleUser } from '../../common/types/objects';
import type { StoreState } from '../../common/types/store';
import type { ListApiResponse } from '../../common/types/util-types';
import { BASE_URL } from '../../common/constants';
import _ from 'lodash';

type Counts = {
  upcoming: number;
  draft: number;
  past: number;
  canceled: number;
};

export type ReceiveUserEventsAction = {
  type: 'events/user/RECEIVE_EVENTS';
  items: EventForUserDto[];
  counts: Counts;
};

export type ReceiveAdminEventsAction = {
  type: 'events/admin/RECEIVE_EVENTS';
  items: EventForAdminDto[];
  counts: Counts;
  strategy: 'append' | null;
};

export type DuplicateEventAction = {
  type: 'events/DUPLICATE_EVENT',
  response: {
    data: EventForAdminDto
  }
  originalEventId: string;
};

export type ReceiveEventsAction = ReceiveUserEventsAction | ReceiveAdminEventsAction;

type FetchEventsApiResponse = ListApiResponse<Event[], Counts>;

export type ActualReceiveEventsAction = ThunkAction<Promise<FetchEventsApiResponse>, StoreState, unknown, ReceiveEventsAction>;

export const fetchEvents = (
  nextCursor: string | null,
  filter: {
    filter?: EventStatus,
    networkId: string | null,
  },
  limit = 16,
): ActualReceiveEventsAction => async (
  dispatch,
  getState,
) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    limit,
    cursor: nextCursor,
    filter: filter.filter,
  });

  const request = await Api.get(`/v1/organisations/${selected.id}/events?${query}`) as
    FetchEventsApiResponse & { data: EventForAdminDto[] };

  dispatch({
    type: 'events/admin/RECEIVE_EVENTS',
    items: request.data,
    counts: request.meta.counts,
    strategy: nextCursor ? 'append' : null,
  });

  return request;
};

export type ReceiveNetworkEventsAction = {
  type: 'events/user/RECEIVE_EVENTS',
  items: Event[],
  strategy: 'append' | null;
};

type FetchMyEventsApiResponse = ListApiResponse<EventForUserDto[], Counts>;
type ActualReceiveMyEventsAction = ThunkAction<
Promise<FetchMyEventsApiResponse>,
StoreState,
unknown,
ReceiveNetworkEventsAction
>;

export const fetchMyEvents = (
  nextCursor: string | null,
  filter: { filter?: string },
  limit = 16,
): ActualReceiveMyEventsAction => async (
  dispatch,
  getState,
) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    limit,
    filter: filter.filter,
  });

  const request = await Api.get<FetchMyEventsApiResponse>(`/v1/organisations/${selected.id}/users/me/events?${query}`);

  dispatch({
    type: 'events/user/RECEIVE_EVENTS',
    items: request.data,
    counts: request.meta.counts,
    strategy: nextCursor ? 'append' : null,
  });

  return request;
};

export type ReceiveEventAction = {
  type: 'events/RECEIVE_EVENT';
  item: Event;
};

type ActualReceiveEventAction = ThunkAction<Promise<ReceiveEventAction>, StoreState, unknown, ReceiveEventAction>;

export const fetchEvent = (id: string): ActualReceiveEventAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  type ApiResponse = { data: EventForAdminDto };

  const { data: item } = await Api.get<ApiResponse>(`/v1/organisations/${selected.id}/events/${id}`);

  return dispatch({
    type: 'events/RECEIVE_EVENT',
    item,
  });
};

export type ReceiveMyEventAction = {
  type: 'events/RECEIVE_EVENT';
  item: Event;
};

type ActualReceiveMyEventAction = ThunkAction<Promise<ReceiveEventAction>, StoreState, unknown, ReceiveEventAction>;

export const fetchMyEvent = (id: string): ActualReceiveMyEventAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  type ApiResponse = { data: EventForUserDto };

  const { data: item } = await Api.get<ApiResponse>(`/v1/organisations/${selected.id}/users/me/events/${id}`);

  return dispatch({
    type: 'events/RECEIVE_EVENT',
    item,
  });
};

export type CancelEventAction = {
  type: 'events/CANCEL_EVENT';
  eventId: string;
};

export type ActualCancelEventAction = ThunkAction<Promise<CancelEventAction>, StoreState, unknown, CancelEventAction>;

export const cancelEvent = (id: string): ActualCancelEventAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.post(`/v1/organisations/${selected.id}/events/${id}/cancel`);

  return dispatch({
    type: 'events/CANCEL_EVENT',
    eventId: id,
  });
};

export type CreateEventAction = {
  type: 'events/CREATE_EVENT';
  item: EventForAdminDto;
};

type ActualCreateEvent = ThunkAction<Promise<CreateEventAction>, StoreState, unknown, CreateEventAction>;

export const createEvent = (payload: Partial<Event>): ActualCreateEvent => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item } = await Api.post(`/v1/organisations/${selected.id}/events`, payload) as { data: EventForAdminDto };

  return dispatch({
    type: 'events/CREATE_EVENT',
    item,
  });
};

export type UpdateEventAction = {
  type: 'events/UPDATE_EVENT';
  item: EventForAdminDto;
};

export type ActualUpdateEventAction = ThunkAction<Promise<UpdateEventAction>, StoreState, unknown, UpdateEventAction>;

export const updateEvent = (id: string, payload: Partial<Event>): ActualUpdateEventAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const url = `/v1/organisations/${selected.id}/events/${id}`;
  const { data: item } = await Api.put(url, payload) as {
    data: EventForAdminDto
  };

  return dispatch({
    type: 'events/UPDATE_EVENT',
    item,
  });
};

export type AttendEventAction = {
  type: 'events/ATTEND_EVENT';
  id: string;
  user: SimpleUser & {
    first_name: string | null,
    email: string | null,
    last_name: string | null,
    username: string | null,
  };
};

type ActualAttendEventAction = ThunkAction<Promise<AttendEventAction>, StoreState, unknown, AttendEventAction>;

export const attendEvent = (id: string): ActualAttendEventAction => async (dispatch, getState) => {
  const { organisation: { selected }, loggedUser } = getState();

  await Api.post(`/v1/organisations/${selected.id}/events/${id}/attendance`, {
    is_going: true,
  });

  return dispatch({
    id,
    type: 'events/ATTEND_EVENT',
    user: _.pick(loggedUser.user, [
      'id', 'first_name', 'last_name', 'full_name', 'email', 'username',
      'profile_img', 'last_active'
    ])
  });
};

export type AbsentEventAction = {
  type: 'events/ABSENT_EVENT';
  id: string;
  userId: string;
};

type ActualAbsentEventAction = ThunkAction<Promise<AbsentEventAction>, StoreState, unknown, AbsentEventAction>;

export const absentEvent = (id: string): ActualAbsentEventAction => async (dispatch, getState) => {
  const { organisation: { selected }, loggedUser } = getState();

  await Api.post(`/v1/organisations/${selected.id}/events/${id}/attendance`, {
    is_going: false,
  });

  return dispatch({
    type: 'events/ABSENT_EVENT',
    userId: loggedUser.user.id,
    id
  });
};

export type DeleteEventAction = {
  type: 'events/DELETE_EVENT';
  eventId: string;
};

export type ActualDeleteEventAction = ThunkAction<Promise<DeleteEventAction>, StoreState, unknown, DeleteEventAction>;

export const deleteEvent = (id: string): ActualDeleteEventAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.delete(`/v1/organisations/${selected.id}/events/${id}`);

  return dispatch({
    type: 'events/DELETE_EVENT',
    eventId: id,
  });
};

export type ActualGetGuestsList = ThunkAction<Promise<Blob>, StoreState, unknown, AnyAction>;

export const fetchGuestsListCSV = (id: string): ActualGetGuestsList => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const options: RequestInit = {
    headers: await getApiHeaders(),
    credentials: 'include',
    cache: 'no-cache',
  };

  return fetch(`${BASE_URL}/v1/organisations/${selected.id}/events/${id}/guests/export`, options).then((res) => res.blob());
};

type GuestListResponse = ListApiResponse<SimpleUser[], GuestCounts>;

type ActualFetchGuests = ThunkAction<Promise<GuestListResponse>, StoreState, unknown, AnyAction>;

export const fetchGuests = (
  eventId: string,
  nextCursor: string | undefined,
  filters: Partial<{
    filter: string;
    searchTerm: string;
  }>,
  limit = 10,
): ActualFetchGuests => (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    cursor: nextCursor || true,
    limit,
    filter: filters.filter,
    q: filters.searchTerm,
  });

  return Api.get<GuestListResponse>(`/v1/organisations/${selected.id}/events/${eventId}/guests?${query}`);
};
