import * as R from 'ramda';
import Api from '../../../common/services/api';

export const SOCIAL_OBJECT_SEEN = 'social/OBJECT_SEEN';
export const SOCIAL_CLEAR_SEEN_QUEUE = 'social/CLEAR_SEEN_QUEUE';

export const processQueue = () => (dispatch, getState) => {
  const { queue } = getState().social.seen;
  const ids = R.reject(R.isNil, queue);

  if (ids.length === 0) return;

  Api.post('/v2/seen_objects', { ids });

  return dispatch({ type: SOCIAL_CLEAR_SEEN_QUEUE });
};

export default (objectId) => ({ type: SOCIAL_OBJECT_SEEN, objectId });
