import * as React from 'react';
import * as R from 'ramda';
import { Trans, withTranslation } from 'react-i18next';
import Card from '../../../../common/components/card';
import { Button } from '../../../../common/components/button';
import UserInput from '../../../../common/components/user-input';
import SearchBar from '../../../../common/components/search-bar';
import PureList from '../../../../common/components/list';
import ChannelItem from '../channels/item';
import { PostMessageForm } from '../../forms/post-message';
import { ETimelineTypes } from '../../definitions';
import {
  Props, State,
  HandleFocus, HandleBlur, HandleSelectTimeline, HandleClearTimeline, HandleReset, HandleSearch,
} from './types';

class PostMessageComponent extends React.Component<Props, State> {
  static props: Props;

  handleFocus: HandleFocus;
  handleBlur: HandleBlur;
  handleSelectTimeline: HandleSelectTimeline;
  handleClearTimeline: HandleClearTimeline;
  handleReset: HandleReset;
  handleSearch: HandleSearch;

  constructor(props: Props) {
    super(props);

    this.state = {
      initialised: false,
      focused: false,
      timeline: props.disableHeader && props.channel
        ? props.channel
        : null,
      search: '',
    };

    this.handleFocus = () => this.setState({
      focused: true,
      initialised: true,
      timeline: this.state.timeline === null
        // @ts-expect-error
        ? this.props.channel.type !== ETimelineTypes.FEED ? this.props.channel : null
        : this.state.timeline,
    });
    this.handleBlur = () => this.setState({ focused: false });
    this.handleSelectTimeline = (type, id, parent) => this.setState({ timeline: { type, id, parent } });
    this.handleClearTimeline = () => this.setState({ timeline: null });
    this.handleReset = () => this.setState({
      focused: false,
      initialised: false,
      timeline: null,
    });
    this.handleSearch = (query) => this.setState({ search: query ? query.toLowerCase() : '' });
  }

  // @ts-expect-error
  UNSAFE_componentWillUpdate(nextProps) {
    const { channel } = this.props;

    // @ts-expect-error
    if (this.state.timeline && `${nextProps.channel.type}${nextProps.channel.id}` !== `${channel.type}${channel.id}`) {
      // @ts-expect-error
      this.state.timeline = nextProps.channel.type !== ETimelineTypes.FEED ? nextProps.channel : null;
    }
  }

  render() {
    const { initialised, focused, timeline, search } = this.state;
    const {
      loggedUser, organisation, network, disableHeader, t,
    } = this.props;

    let content;

    if (((!timeline && !focused) || !initialised)) {
      content = (
        <Card.Content>
          <UserInput user={loggedUser}>
            <textarea
              style={{ width: '100%', height: 18 }}
              className="Form__control__highlighter"
              placeholder={t('social:post_message_placeholder')}
            />
          </UserInput>
          <hr />
          <div className="PostMessage__Actions">
            <Button className="PostMessage__Button--poll" icon="poll__filled">
              <Trans i18nKey="social:post_message_poll" />
            </Button>
            <Button className="PostMessage__Button--attachment" icon="image__filled">
              <Trans i18nKey="social:post_message_attachment_photo_video" />
            </Button>
            <Button className="PostMessage__Button--document" icon="document__filled">
              <Trans i18nKey="social:post_message_attachment_document" />
            </Button>
            <Button icon="more_horiz" />
          </div>
        </Card.Content>
      );
    } else if (!timeline && focused) {
      // @ts-expect-error
      const inSearch = (item) => item.name.toLowerCase().indexOf(search) !== -1;

      const teams = this.props.teams.filter(inSearch);
      const channels = this.props.channels.filter(inSearch);

      content = (
        <>
          <SearchBar
            defaultFocus
            defaultValue={this.state.search}
            debounce={false}
            filter={false}
            placeholder={t('social:post_message_search_bar_placeholder')}
            // @ts-expect-error
            onSearch={this.handleSearch}
          />

          <div className="Channels">
            <h5 className="balloon__title">
              <span>
                {network.name}
                {' '}
                <small>{teams.length}</small>
              </span>
            </h5>
            {network.name.toLowerCase().indexOf(search) !== -1 && (
              <ChannelItem
                item={{ ...network, image: '/static/images/community_icon.svg?url' }}
                onSelectChannel={() => this.handleSelectTimeline(ETimelineTypes.NETWORK, network.id, network)}
              />
            )}
            <PureList
              items={teams}
              renderRow={ChannelItem}
              rowProps={(team) => ({
                sub: true,
                onSelectChannel: () => this.handleSelectTimeline(ETimelineTypes.TEAM, team.id, team),
              })}
            />
            {channels.length > 0 && (
              <>
                <h5 className="balloon__title">
                  <span>
                    {organisation.name}
                    {' '}
                    <small>{channels.length}</small>
                  </span>
                </h5>
                <PureList
                  // @ts-expect-error
                  items={channels.map((item) => R.assoc('image', item.theme.logo_url, item))}
                  renderRow={ChannelItem}
                  rowProps={(channel) => ({
                    // @ts-expect-error
                    onSelectChannel: () => this.handleSelectTimeline(ETimelineTypes.CHANNEL, channel.id, channel),
                  })}
                />
              </>
            )}
          </div>
        </>
      );
    } else if (timeline) {
      content = (
        <>
          {!disableHeader && (
            <Card.Header>
              <Trans i18nKey="social:post_message_post_in" />
              &nbsp;
              {/* @ts-expect-error */}
              <span className="Post__Message__TargetName">{timeline.parent.name}</span>
              &nbsp;
              {/* @ts-expect-error */}
              {timeline.parent.users_count !== undefined && (
                <>
                  {/* @ts-expect-error */}
                  (<Trans i18nKey="social:post_message_target_users_count" values={{ count: timeline.parent.users_count }} />)
                </>
              )}
              <div className="Post__Message__ChangeTarget pull-right" onClick={this.handleClearTimeline}>
                <Trans i18nKey="social:post_message_change_target" />
              </div>
            </Card.Header>
          )}
          <PostMessageForm
            {...this.props}
            // @ts-expect-error
            timeline={timeline}
            isFocused={focused}
            postPostMessage={this.handleReset}
          />
        </>
      );
    }

    const containerClassNames = ['PostMessage'];
    if (focused) containerClassNames.push('PostMessage--focused');

    return (
      <>
        {focused && <div className="PostMessage__Backdrop" role="dialog" onClick={this.handleBlur} />}
        <Card containerClassName={containerClassNames.join(' ')} onClick={!focused ? this.handleFocus : undefined}>
          {content}
        </Card>
      </>
    );
  }
}

export default withTranslation(undefined, { withRef: true })(PostMessageComponent);
