import React from 'react';
import { omit } from 'lodash';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

import { Checkbox } from '../checkbox';
import type { CheckboxOwnProps } from '../checkbox';

type ReduxFormFieldProps = Omit<CheckboxOwnProps, 'value' | 'onChange' | 'onFocus' | 'onBlur'>;

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export function CheckboxReverseInputHookForm<T extends FieldValues>(props: ReduxFormFieldProps & UseControllerProps<T>) {
  const { field, fieldState } = useController(props);
  return (
    <Checkbox
      {...props}
      {...field}
      value={!field.value}
      onChange={(newValue) => field.onChange(!newValue)}
      error={fieldState.error?.message || fieldState.error?.message === ''}
    />
  );
}

/**
 * Reverses the value before setting it and reverses it back before calling onChange.
 */
const ReduxFormField = ({ input, meta, ...props }: WrappedFieldProps & ReduxFormFieldProps) => {
  return (
    <Checkbox
      error={meta.touched && meta.error}
      {...input}
      value={!input.value}
      onChange={(newValue) => input.onChange(!newValue)}
      {...omit(props, ['meta', 'input'])}
    />
  );
};

/**
 * @deprecated This component is only
 * here to support state management of legacy code, that should actually
 * be handled by the component implementing the form
 */
const ReduxFormCheckboxReverse = ({ name, ...props }: BaseFieldProps & ReduxFormFieldProps) => (
  <Field
    name={name}
    component={ReduxFormField}
    props={props}
  />
);

export default ReduxFormCheckboxReverse;
