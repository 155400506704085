import { createSelector } from 'reselect';

import memoize from '../../common/utils/memoize';
import { findById } from './reducer';

import type { StoreState } from '../../common/types/store';
import type { EventForAdminDto, EventForUserDto } from './types';

const getEventsIds = (state: StoreState) => state.events.ids;
const getCommonItems = (state: StoreState) => state.events.items.common;
const getCommonItem = (state: StoreState, id: string) => state.events.items.common[id];

const getAdminFragments = (state: StoreState) => state.events.items.admin;
export const getAdminScopeEvents = createSelector(
  [getEventsIds, getCommonItems, getAdminFragments],
  (ids, common, admin): EventForAdminDto[] => ids.map((id) => admin[id] && ({
    ...common[id],
    ...admin[id],
  })),
);

const getAdminFragment = (state: StoreState, id: string) => state.events.items.admin[id];
export const getAdminScopeEvent = createSelector(
  [getCommonItem, getAdminFragment],
  (common, admin): EventForAdminDto => admin && ({ ...common, ...admin }),
);

const getUserFragment = (state: StoreState, id: string) => state.events.items.user[id];
export const getUserScopeEvent = createSelector(
  [getCommonItem, getUserFragment],
  (common, user): EventForUserDto => user && ({ ...common, ...user }),
);

const getUserFragments = (state: StoreState) => state.events.items.user;
export const getUserScopeEvents = createSelector(
  [getEventsIds, getCommonItems, getUserFragments],
  (ids, common, user): EventForUserDto[] => ids.map((id) => user[id] && ({
    ...common[id],
    ...user[id],
  })),
);

const getAvailableEvents = (state: StoreState) => state.events.available;
export const available = createSelector(
  [getAvailableEvents, getCommonItems, getUserFragments],
  (ids, common, user): EventForUserDto[] => ids.map((id) => user[id] && ({
    ...common[id],
    ...user[id],
  })),
);

const getItems = (state: StoreState) => state.events.ids;
export const items = memoize.createSelector(
  [getItems],
  // @ts-expect-error
  (state: any, ids: string[]) => ids
    .map((id) => findById(state, id))
    .filter((e) => !!e),
);
