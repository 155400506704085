import React from 'react';
import { connect } from 'react-redux';
import Message from './message';
import Exchange from './exchange';
import { EObjectTypes } from '../../definitions';
import * as timelineSelector from '../../selectors/timeline';

const FeedItemComponent = ({ item, ...props }) => {
  if (!item) return null;

  switch (item.type) {
    case EObjectTypes.MESSAGE:
      return <Message item={item} {...props} />;
    case EObjectTypes.EXCHANGE:
      return <Exchange item={item} {...props} />;
    default:
      return null;
  }
};

const mapStateToProps = () => {
  const itemSelector = timelineSelector.item();

  return (state, props) => ({
    item: itemSelector(state, props.item),
  });
};

const mapDispatchToProps = {
  translateMessage: require('../../actions').translateMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedItemComponent);
