import React, { memo, useState } from 'react';
import { Trans } from 'react-i18next';
import Icon from '../../../../common/components/icon';
import Image from '../../../../common/components/image';
import Dropdown from '../../../../common/components/dropdown';
import ImagePlaceholder from '../../../../common/components/image-placeholder';

export type Props = {
  id?: string,
  item: {
    id?: string,
    name: string,
    image?: string,
    member_count?: number,
    is_member?: boolean,
    membership?: {
      hidden_from_feed: boolean;
      enabled_notifications: boolean;
    }
  },
  selected?: boolean,
  sub?: boolean,
  onSelectChannel: (id?: string) => void,
  onHideChannel?: (hidden: boolean) => void,
  onMute?: (muted: boolean) => void,
};

const ChannelItemComponent = ({
  id, item, selected, sub, onSelectChannel, onHideChannel, onMute,
}: Props) => {
  const [isOpen, setOpen] = useState(false);

  let className = 'Channels__Item';
  if (selected) className += ' Channels__Item--active';
  if (isOpen) className += ' Channels__Item--open';

  return (
    <div
      id={id}
      className={className}
      onClick={() => onSelectChannel(item.id)}
    >
      <div className="Channels__Item__Image">
        {item.image && (
          <Image exclude src={item.image} alt={item.name} size={36} />
        )}
        {!item.image && sub && (
          <Icon type="subdirectory_arrow_right" />
        )}
        {!item.image && !sub && (
          <ImagePlaceholder size={36} name={item.name} />
        )}
      </div>
      <div className="Channels__Item__Info">
        <div>
          {item.name}
          {onHideChannel && item.membership?.hidden_from_feed && (
            <Icon
              type="visibility_off"
              className="Channels__Item__Status"
            />
          )}
          {onMute && !item.membership?.enabled_notifications && (
            <Icon
              type="notifications_off"
              className="Channels__Item__Status"
            />
          )}
        </div>
        {item.member_count !== undefined && (
          <small>
            <Trans i18nKey="social:channels_item_members" values={{ count: item.member_count }} />
          </small>
        )}
      </div>
      <Dropdown
        alignRight
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        toggle={<Icon type="more_vert" className="Channels__Item__More" />}
      >
        {onHideChannel && (
          <Dropdown.Item onClick={() => onHideChannel(!item.membership?.hidden_from_feed)}>
            <Trans i18nKey={item.membership?.hidden_from_feed ? 'social:channels_item_unhide' : 'social:channels_item_hide'} />
          </Dropdown.Item>
        )}
        {onMute && (
          <Dropdown.Item onClick={() => item.membership && onMute(item.membership.enabled_notifications)}>
            <Trans i18nKey={
                !item.membership?.enabled_notifications ?
                  'social:channels_item_unmute' : 'social:channels_item_mute'
              }
            />
          </Dropdown.Item>
        )}
      </Dropdown>
    </div>
  );
};

export default memo(ChannelItemComponent);
