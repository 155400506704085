import React from 'react';
import * as R from 'ramda';

import ImagePreview from '../../file-preview/image';
import FilePreview from '../../file-preview/file';
import { canPreviewAttachment } from '../../../utils/file';
import { Attachment, FileInProgress } from '@common/types/objects';

type AttachmentsOwnProps = {
  fields: (Attachment & FileInProgress)[];
  addInput: JSX.Element;
  onRemove: (index: number) => void;
};

const FormAttachments = ({ fields, addInput, onRemove }: AttachmentsOwnProps) => {
  if (fields.length === 0) return null;

  const remove = (id: string) => {
    const index = R.findIndex(R.propEq('id', id), fields);
    const file = fields[index];
    if (file && file.cancelPromise) file.cancelPromise();
    onRemove(index);
  };

  const images = fields
    .filter(canPreviewAttachment)
    .map((attachment) => (
      <ImagePreview
        key={attachment.id || attachment.file_name}
        file={attachment}
        size={70}
        onRemove={() => remove(attachment.id)}
      />
    ));
  const files = fields
    .filter(R.complement(canPreviewAttachment))
    .map((attachment) => (
      <FilePreview
        key={attachment.id || attachment.file_name}
        file={attachment}
        onRemove={() => remove(attachment.id)}
      />
    ));

  return (
    <div className="Attachments">
      {images.length > 0 && (
        <div className="Attachments__Images">
          {images}
          {addInput}
        </div>
      )}
      {files.length > 0 && (
        <div className="Attachments__Files">
          {files}
        </div>
      )}
    </div>
  );
};

export default FormAttachments;
