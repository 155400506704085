import React from 'react';
import { FieldArray } from 'redux-form';

import FormAttachments from './attachments';

export const AttachmentsHookForm = ({ fields, remove, ...props }) => (
  <FormAttachments
    {...props}
    fields={fields}
    onRemove={(index) => remove(index)}
  />
);

const ReduxFormFieldMultiple = ({ fields, ...props }) => (
  <FormAttachments
    {...props}
    fields={fields.getAll()}
    onRemove={(index) => fields.remove(index)}
  />
);

export default (props) => (
  <FieldArray
    {...props}
    component={ReduxFormFieldMultiple}
  />
);
