import * as React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import document from 'global/document';
import AsyncList from '../../../../common/components/list/async';
import Placeholder from '../../../../common/components/placeholder';
import Spinner from '../../../../common/components/spinner';
import FeedItemComponent from '../feed-item';
import * as userSelector from '../../../core/selectors/logged-user';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as networkSelector from '../../../network/selectors/network';

const ShowNewComponent = ({ count, onShowNew }) => (
  <div className="Feed__ShowMore" onClick={onShowNew} role="button">
    <Trans i18nKey="social:feed_load_new" values={{ count }} />
  </div>
);

const ShowMoreComponent = ({ onShowMore, isFetching }) => (isFetching
  ? <Spinner centered size="large" />
  : (
    <div className="Feed__ShowMore" onClick={onShowMore} role="button">
      <Trans i18nKey="social:feed_load_more" />
    </div>
  )
);

const FeedComponent = ({
  listRef,
  data,
  renderRow = FeedItemComponent,
  items,
  rowProps,
  header,
  footer,
  placeholder,
  hideSpinner,
  containerClassName,
  disableScrollListener,
  t,
  ...props
}) => (
  <AsyncList
    ref={listRef}
    containerClassName={containerClassName ? `Feed ${containerClassName}` : 'Feed'}
    hideSpinner={hideSpinner}
    items={items}
    data={data}
    renderRow={renderRow}
    rowProps={{
      ...props,
      ...rowProps,
    }}
    scrollContainer={!disableScrollListener && document}
    onBottomTriggerOffset={2000}
    header={header}
    footer={footer}
    placeholder={placeholder !== false
      ? placeholder || (
        <Placeholder
          size="small"
          title={t('social:feed_placeholder_title')}
          description={t('social:feed_placeholder_description')}
          image="/static/images/placeholders/timeline.svg"
        />
      )
      : null}
    ShowNewComponent={ShowNewComponent}
    ShowMoreComponent={ShowMoreComponent}
  />
);

const mapStateToProps = (state) => ({
  organisation: organisationSelector.selected(state),
  loggedUser: userSelector.selected(state),
  network: networkSelector.selected(state),
});

const mapDispatchToProps = {
  getComments: require('../../actions/fetch-comments').default,
  onHighFive: require('../../actions/highfive-message').default,
  onDelete: require('../../actions/delete-message').default,
  onDeleteComment: require('../../actions/delete-comment').default,
  onUpdate: require('../../actions/update-message').default,
  onVotePoll: require('../../actions/vote-poll').default,
  onAccept: require('../../../flexchange/actions/accept-exchange').default,
  onDecline: require('../../../flexchange/actions/decline-exchange').default,
  onRead: require('../../actions/seen').default,
  onToggleImportant: require('../../actions').toggleImportant,
  onToggleComments: require('../../actions').toggleComments,
  onMarkAsRead: require('../../actions').markAsRead,
  onToggleMute: require('../../actions').toggleMute,
  onHighFiveComment: require('../../actions').highFiveComment,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FeedComponent));
