import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import moment from 'moment';
import Card from '../../../../../common/components/card';
import Profile from '../../../../../common/components/profile';
import { Button } from '../../../../../common/components/button';
import Icon from '../../../../../common/components/icon';

class FeedExchangeCard extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    onAccept: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onOpenExchange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { accepting: false, declining: false };

    this.handleAction = this.handleAction.bind(this);
  }

  handleAction(fn = () => null, state) {
    fn(this.props.item.id);

    this.setState({ [state]: true });
  }

  render() {
    const { accepting, declining } = this.state;
    const {
      loggedUser, item, onAccept, onDecline, t,
    } = this.props;

    const createdInText = item.created_in && t('social:feed_item_exchange_send_to', { values: item.created_in.values });
    const timestamp = moment(item.created_at).calendar();
    const getTime = (time) => moment(time).format('HH:mm');
    const date = moment(item.date).format('dddd D MMMM');
    const title = !item.start_time && !item.end_time
      ? item.title
      : `${getTime(item.start_time)} - ${item.end_time
        ? getTime(item.end_time)
        : t('social:feed_item_exchange_no_end_time')}`;

    return (
      <Card containerClassName="Exchange">
        <Card.Header>
          <small className="pull-right open" onClick={() => this.props.onOpenExchange(item.id)}>
            <Trans i18nKey="social:feed_item_exchange_open_flexchange" />
          </small>

          <Profile user={item.user} meta={createdInText} enablePopover={item.user_id !== loggedUser.id}>
            <small>{timestamp}</small>
          </Profile>
        </Card.Header>

        <Card.Content>
          <div className="Exchange__Info">
            <div className="Exchange__Info__Title">
              {title}
            </div>
            <div className="Exchange__Info__Date">
              {date}
            </div>
          </div>

          <div className="Exchange__Actions">
            <Button
              type="default"
              size="large"
              center
              onClick={() => this.handleAction(onAccept, 'accepting')}
              isLoading={accepting}
              disabled={declining}
            >
              <Icon type="check" color="green" className="Exchange__Actions--accept" />
              <Trans i18nKey="social:feed_item_exchange_accept" />
            </Button>

            <Button
              type="default"
              size="large"
              center
              onClick={() => this.handleAction(onDecline, 'declining')}
              isLoading={declining}
              disabled={accepting}
            >
              <Icon type="close" color="red" className="Exchange__Actions--decline" />
              <Trans i18nKey="social:feed_item_exchange_reject" />
            </Button>
          </div>
        </Card.Content>
      </Card>
    );
  }
}

export default withTranslation()(FeedExchangeCard);
