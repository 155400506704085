import * as R from 'ramda';
import Api from '../../../common/services/api';
import { ETimelineTypes, EFeedFilterTypes } from '../definitions';
import { SOCIAL_REQUEST_FEED, SOCIAL_RECEIVE_FEED, SOCIAL_RECEIVE_IMPORTANT_MESSAGES, SOCIAL_RECEIVE_UNREAD_MESSAGES } from './index';
// import payload from "./payload.json";

const addObjectId = (object) => ({
  ...object,
  source: {
    ...object.source,
    object_id: object.id,
  },
});

// type Response = { data: Array<Object>, meta: Object }

export const receiveMessages = ({ data, meta }, strategy) => (dispatch) => {
  const { related, scheduled_count: scheduledCount } = meta;

  const items = R.pipe(R.filter(R.prop('source')), R.map(addObjectId))(data);

  return dispatch({
    type: SOCIAL_RECEIVE_FEED,
    items,
    related,
    scheduledCount,
    strategy,
  });
};

export const receiveImportantMessages = ({ data, meta }, strategy) => (dispatch) => {
  const { related } = meta;

  const items = R.pipe(R.filter(R.prop('source')), R.map(addObjectId))(data);

  return dispatch({
    type: SOCIAL_RECEIVE_IMPORTANT_MESSAGES,
    items,
    related,
    strategy,
  });
};

export const receiveUnreadMessages = ({ data, meta }, strategy) => (dispatch) => {
  const { related } = meta;

  const items = R.pipe(R.filter(R.prop('source')), R.map(addObjectId))(data);

  return dispatch({
    type: SOCIAL_RECEIVE_UNREAD_MESSAGES,
    items,
    related,
    strategy,
  });
};

export default (nextCursor, filter = {}, limit = 10, clear = false) => async (dispatch, getState) => {
  const { organisation: { selected: { id: organisationId } } } = getState();

  const { type, id } = filter;

  if (filter.filter !== EFeedFilterTypes.IMPORTANT_UNREAD) {
    dispatch({
      type: SOCIAL_REQUEST_FEED,
      channel: { type, id },
      clear,
    });
  }

  let baseUrl;

  if (type === ETimelineTypes.FEED) {
    baseUrl = `/v3/networks/${id}/feed`;
  } else if (type === ETimelineTypes.NETWORK) {
    baseUrl = `/v3/networks/${id}/messages`;
  } else if (type === ETimelineTypes.TEAM) {
    baseUrl = `/v3/teams/${id}/messages`;
  } else if (type === ETimelineTypes.ORGANISATION) {
    baseUrl = `/v3/organisations/${id}/messages`;
  } else if (type === ETimelineTypes.CHANNEL) {
    baseUrl = `/v1/organisations/${organisationId}/channels/${id}/messages`;
  } else if (type === ETimelineTypes.EVENT) {
    baseUrl = `/v1/organisations/${organisationId}/events/${id}/messages`;
  }

  if (!baseUrl) return;

  const queryObject = {
    q: filter.q,
    cursor: nextCursor || true,
    sort_by: filter.sort_by,
    limit,
  };

  if (filter.filter) queryObject.filter = filter.filter;

  const query = Api.utils.toQuery(queryObject);

  // return payload;
  return Api.get(`${baseUrl}?${query}`)
    .then((result) => {
      if (filter.filter === EFeedFilterTypes.IMPORTANT_UNREAD) {
        return {
          data: result.data,
          meta: {
            ...result.meta,
            pagination: {
              next_cursor: null,
              limit: result.meta.pagination.limit,
            },
          },
        };
      }

      return result;
    });
};
