import * as R from 'ramda';
import memoize from '../../../common/utils/memoize';
import * as usersReducer from '../../core/reducers/users';
import * as messagesReducer from '../reducers/messages';
import * as commentsReducer from '../reducers/comments';
import * as exchangesReducer from '../../flexchange/reducers/exchanges';
import { EObjectTypes, ETimelineTypes, EMessageTypes } from '../definitions';
import { EParentTypes } from '../../../common/definitions';

const isType = R.propEq('type');
const isObjectType = R.propEq('object_type');
const isNotObjectType = R.complement(isObjectType);

const getSelectedNetwork = (state) => state.network.selected;
const getTeams = (state) => state.network.teams.items;
const getFeedItems = (state) => state.social.feed.items;
const getImportantMessages = (state) => state.social.feed.important;
const getUnreadMessages = (state) => state.social.feed.unread;
const getDate = (state, date) => date || state.network.birthdays.date;
const getFeedPagination = (state) => state.social.pagination;
const getFeedItemLikes = (state, object) => R.cond([
  [isNotObjectType(EObjectTypes.EXCHANGE), () => state.social.likes[object.source_id]],
  [R.T, R.T],
])(object);
const getFeedItemComments = (state, object) => R.cond([
  [isNotObjectType(EObjectTypes.EXCHANGE), () => state.social.comments.messages[object.source_id]],
  [R.T, R.T],
])(object);

// Convert url parameters to object with type and optionally id
const getSelectedTimelineFromUrl = (state, params) => {
  if (!params.type) {
    return { type: ETimelineTypes.FEED, id: state.network.selected };
  } if (params.type === ETimelineTypes.NETWORK) {
    return { type: ETimelineTypes.NETWORK, id: state.network.selected };
  } if (params.type === ETimelineTypes.ORGANISATION) {
    return { type: ETimelineTypes.ORGANISATION, id: state.organisation.selected.id };
  }

  return {
    type: params.type,
    id: params.id,
  };
};

export const selected = memoize.createSelector(
  [getSelectedTimelineFromUrl],
  (state, { type, id }) => {
    let parent = {};
    let canCreate = true;
    let usersCount;

    if (type === ETimelineTypes.NETWORK) {
      parent = state.networks.items[id];
      parent.membership = state.loggedUser.memberships.networks[id];

      usersCount = state.network.users.counts.total;
    } else if (type === ETimelineTypes.TEAM) {
      parent = state.network.teams.items[id];
      parent.membership = state.loggedUser.memberships.teams[id];

      usersCount = state.network.teams.items[id].users_count;
    } else if (type === ETimelineTypes.ORGANISATION) {
      parent = state.organisation.selected;
      canCreate = false;

      usersCount = state.organisation.users.counts.total;
    } else if (type === ETimelineTypes.CHANNEL) {
      parent = state.organisation.channels.items[id];
      parent.membership = state.loggedUser.memberships.channels[id];

      canCreate = !!parent.settings.can_post;
      usersCount = parent?.member_count;
    }

    return {
      type,
      id,
      parent,
      users_count: usersCount,
      can_create: canCreate,
    };
  },
);

export const feed = memoize.createSelector(
  [getFeedItems],
  (state, items) => R.pipe(
    R.reject(R.isNil),
    R.filter(R.both(R.has('source_id'), R.has('object_type'))),
  )(items),
);

export const important = memoize.createSelector(
  [getImportantMessages],
  (state, items) => R.pipe(
    R.reject(R.isNil),
    R.filter(R.both(R.has('source_id'), R.has('object_type'))),
    R.sort(R.descend(R.prop('created_at'))),
  )(items),
);

export const unread = memoize.createSelector(
  [getUnreadMessages],
  (state, items) => R.pipe(
    R.reject(R.isNil),
    R.filter(R.both(R.has('source_id'), R.has('object_type'))),
    R.sort(R.descend(R.prop('created_at'))),
  )(items),
);

// TODO Message | Exchange
const getFeedItem = (state, object) => R.cond([
  [isObjectType(EObjectTypes.EXCHANGE), () => state.flexchange.exchanges.items[object.source_id]],
  [R.T, () => state.social.messages[object.source_id]],
])(object);

export const item = memoize.createItemSelector(
  [getFeedItem, getFeedItemLikes, getFeedItemComments, getTeams],
  (state, feedItem) => R.cond([
    [R.isNil, R.always(null)],
    [isType(EObjectTypes.MESSAGE), () => messagesReducer.findById(state, feedItem.id)],
    [isType(EObjectTypes.ORGANISATION_MESSAGE), () => messagesReducer.findById(state, feedItem.id)],
    [isType(EObjectTypes.EXCHANGE), () => exchangesReducer.findById(state, feedItem.id)],
    [R.T, R.always(null)],
  ])(feedItem),
);

const getSelectedNetworkUsers = (state) => state.network.users.all;

export const colleagues = memoize.createSelector(
  [getSelectedNetworkUsers],
  (state, userIds) => R.pipe(
    R.take(12),
    R.map(usersReducer.findById(state)),
    R.reject(R.isNil),
  )(userIds),
);

const getBirthdaysForDate = (state, date) => state.network.birthdays.dates[date || state.network.birthdays.date];

export const birthdaysForDate = memoize.createSelector(
  [getBirthdaysForDate],
  (state, userIds) => {
    if (!userIds) return [];

    return R.pipe(
      R.map(usersReducer.findById(state)),
      R.reject(R.isNil),
      R.sortBy(R.prop('full_name')), // Sort by name
      R.sort((a, b) => { // Then move logged in user to the front
        if (a.id === state.loggedUser.user.id) return -1;
        if (b.id === state.loggedUser.user.id) return 1;

        return 0;
      }),
    )(userIds);
  },
);

const getBirthdays = (state, date) => state.network.birthdays.dates[date];
const getCongratulationsForDate = (state, date) => state.social.comments.messages[date || state.network.birthdays.date];

export const birthdayMessageForDate = memoize.createSelector(
  [getDate, getBirthdays, getCongratulationsForDate],
  (state, date) => {
    if (!state.network.birthdays.dates[date]) return null;

    return {
      id: date,
      type: EObjectTypes.MESSAGE,
      message_type: EMessageTypes.BIRTHDAY_MESSAGE,
      can_comment: true,
      comments_count: state.network.birthdays.congratulations[date],
      comments: commentsReducer.findByMessage(state, date),
    };
  },
);

export const pagination = memoize.createSelector(
  [getFeedPagination, getSelectedNetwork],
  (state, data) => data,
);

const byParent = (parent) => {
  if (!parent) return [];

  switch (parent.type) {
    case ETimelineTypes.FEED:
      return R.T;
    case ETimelineTypes.NETWORK:
      return R.both(R.propEq('parent_type', EParentTypes.NETWORK), R.propEq('parent_id', parent.id));
    case ETimelineTypes.TEAM:
      return R.both(R.propEq('parent_type', EParentTypes.TEAM), R.propEq('parent_id', parent.id));
    case ETimelineTypes.ORGANISATION:
      return R.both(R.propEq('parent_type', EParentTypes.ORGANISATION), R.propEq('parent_id', parent.id));
    case ETimelineTypes.CHANNEL:
      return R.both(R.propEq('parent_type', EParentTypes.CHANNEL), R.propEq('parent_id', parent.id));
    default:
      return R.F;
  }
};

const getNotifications = (state) => state.notifications.social.items;

export const newMessages = memoize.createSelector(
  [getSelectedTimelineFromUrl, getNotifications],
  (state, parent, unreadNotifications) => R.pipe(
    R.filter(byParent(parent)),
    R.length,
  )(unreadNotifications),
);

export const getOrder = (state) => state.ui.social.order;
